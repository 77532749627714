.event {
  height: 100%;
}

.event-cover {
  position: relative;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.event-title-container {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  text-align: center;
  vertical-align: middle;
  color: $event-title-color;

  .ribbon {
    @media (max-width: $screen-xs-max) {
      width: 100%;
      padding: 15px;
      text-align: left;
      &:after,
      &:before,
      .ribbon-content:after,
      .ribbon-content:before {
        display: none;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    top: inherit;
    bottom: $event-panel-height;
    text-align: left;
  }
}

.event-panel-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $event-panel-height;
  border-top: 1px solid $event-panel-border-color;
  border-bottom: 1px solid $event-panel-border-color;
  background: $event-panel-background;
}

.event-title-date {
  display: inline-block;
  margin-top: 80px;
  padding: 15px;
  border-radius: 3px;
  background: rgba(65, 82, 64, .6);;

  @media (max-width: $screen-xs-max) {
    width: 100%;
    margin-top: 0;
    border-radius: 0;
    background: darken($brand-primary, 20%);
  }
}

.event-panel {
  color: $event-panel-font-color;

  //.container();
}

.event-panel-item {
  display: inline-block;
  padding: 10px 20px;
  border-left: 1px solid $event-panel-border-color;

  &:first-child {
    padding-left: 0;
    border-left: 0;
  }

  @media (max-width: $screen-xs-max) {
    padding-right: 3px;
    padding-left: 3px;

    &.community {
      display: none;
    }
  }
}

.event-content-container {
  padding-top: 20px;
  padding-bottom: 20px;
  background: $event-content-background;
}

.event-content {
  // .container();
}

.event-gratitude {
  margin-top: 20px;
  color: $graditude-font-color;
}

.event-gratitude-link {
  text-decoration: none;
  color: $graditude-links-font-color;

  &:hover {
    color: darken($graditude-links-font-color, 15%);
  }
}

.event-panel-social {
  padding: 0;
}

.event-panel-social-item {
  display: inline-block;
  padding: 10px 0;
  color: $event-panel-font-color;
  font-size: 18px;

  &:hover {
    color: $brand-primary;
  }

  &.facebook {
    margin-left: 7px;
    padding-left: 7px;
    border-left: 1px solid $event-panel-border-color;
    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }
}
