$size: 50px;

.header-container {
  position: relative;
  width: 100%;
  height: $header-height;
  margin: auto;
  background-color: $brand-primary;
  background-position: center;
  background-size: cover;

  &.header-big {
    height: $header-height-big;
  }

  &.header-full {
    height: 100%;
  }
}

.header-no-cover {
  width: 100%;
  height: 100%;
}

.header-cover {
  width: 100%;
  height: 100%;
  background: $header-cover-background;
}

.navigation-container {
  position: relative;
  z-index: 5;
  width: $navigation-width;
  margin: 0 auto;
  padding-top: $navigation-top-margin;
  transition: left ease $navigation-mobile-transition-duration;
  text-align: center;

  &:hover .navigation-link {
    background-color: $brand-primary;

    &:hover {
      border-color: $navigation-bg-color-hover;
      color: $navigation-color;
      text-decoration: none;
      background-color: $navigation-bg-color-hover;
      text-shadow: none;
    }
  }

  @media (max-width: $screen-xs-max) {
    position: fixed;
    left: -100%;
    width: $navigation-mobile-width;
    height: 100%;
    padding-top: 0;
    background: $brand-primary;

    &.navigation-show {
      left: 0;

      .navigation-button-container {
        left: -100%;
      }

      .navigation-mobile-backside {
        height: 100%;
        transition: height 0s 0s, opacity $navigation-mobile-transition-duration;
        opacity: 0.5;
      }
    }
  }

  &.navigation-fixed {
    @media (min-width: $screen-xs-max) {
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.navigation-mobile-backside {
  position: fixed;
  top: 0;
  left: $navigation-mobile-width;
  width: 100%;
  height: 0;
  transition: height 0s $navigation-mobile-transition-duration, opacity $navigation-mobile-transition-duration;
  opacity: 0;
  background: #000000;
}

.navigation-button-chat,
.navigation-button-container {
  position: fixed;
  top: 15px;
  width: $size;
  height: $size;
  border: none;
  border-radius: 50%;
  background: $brand-primary;
  line-height: 0;


  @media (min-width: $screen-xs-max) {
    display: none;
  }
}

.navigation-button-container {
  left: 15px;
  padding: 15px;
  transition: left ease $navigation-mobile-transition-duration;
  border: #ffffff73 1px solid;
}

.navigation-button-chat {
  right: 15px;
  color: #ffffff;
  font-size: 24px;
}

.navigation-social {
  display: none;
  width: $contacts-social-size;
  height: $contacts-social-size;
  margin: 20px;
  padding: 20px;
  color: $contacts-social-color;
  border-radius: 50%;
  background: darken($brand-primary, 20%);
  font-size: $contacts-social-font-size;

  @media (max-width: $screen-xs-max) {
    display: inline-block;
  }
}

.navigation-link {
  z-index: 5;
  display: inline-block;
  width: $navigation-link-width;
  padding-top: 5px;
  padding-bottom: 3px;
  transition: none;
  text-decoration: none;
  text-transform: uppercase;
  color: $navigation-color;
  background-color: rgba(63, 63, 63, .6); // $navigation-bg-color;

  &:first-child {
    border-top-left-radius: $navigation-border-radius;
    border-bottom-left-radius: $navigation-border-radius;

    @media (max-width: $screen-xs-max) {
      margin-top: $navigation-logotype-size + 60;
      border-radius: 0;
    }
  }

  &.last {
    border-top-right-radius: $navigation-border-radius;
    border-bottom-right-radius: $navigation-border-radius;
    width: $navigation-link-width;

    @media (max-width: $screen-xs-max) {
      border-radius: 0;
    }
  }

  @media (max-width: $screen-xs-max) {
    $nav-link-padding: 10px;

    width: 80%;
    margin-right: auto;
    margin-left: auto;
    padding-top: $nav-link-padding;
    padding-bottom: $nav-link-padding;
    border-top: 1px solid $navigation-color;
    border-radius: 0;
    background: none;


  }
}

.navigation-before-logotype {
  margin-right: $navigation-before-logotype;

  @media (max-width: $screen-xs-max) {
    margin-right: auto;
  }
}

.navigation-logotype {
  position: absolute;
  z-index: 10;
  top: calc($navigation-top-margin / 2);
  left: $navigation-logotype-left;
  display: inline-block;
  box-sizing: content-box;
  width: $navigation-logotype-size;
  height: $navigation-logotype-size;
  border: $navigation-logotype-border solid $brand-primary;
  border-radius: 50%;
  background-color: #ffffff;
  background-image: url(../../../public/images/logotype.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 85%;
  line-height: 0;

  &:hover {
    border-color: $navigation-bg-color-hover;
  }

  @media (max-width: $screen-xs-max) {
    top: 23px;
    left: 50%;
    transform: translateX(-50%);
    border-color: $navigation-bg-color-hover;
  }
}

.header-quote {
  position: relative;
  top: 30%;
  align-content: center;
  align-self: center;
  display: block;
  margin: auto;
  padding: 0 10%;
  transform: translateY(-50%);
  text-align: center;
  color: $header-quote-color;
  font-family: $font-family-header-quote;
  font-size: $header-quote-font-size;
  line-height: $header-quote-font-size;

  @media (max-width: $screen-xs-max) {
    font-size: $header-quote-font-size * 0.5;
    line-height: $header-quote-font-size * 0.5;
  }
}

.header-quote-author {
  text-align: right;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  font-size: $header-quote-author-font-size;
}

.header-quote-author-link {
  padding-bottom: 10px;
  color: #ffffff;

  &:hover {
    cursor: pointer;
    border-bottom: 1px solid $header-quote-color;
  }
}

.icon-bar {
  display: block;
  width: 20px;
  height: 2px;
  border-radius: 1px;
  background-color: #ffffff;
}

.icon-bar + .icon-bar {
  margin-top: 4px;
}

#chatra {
  @media (max-width: $screen-xs-max) {
    display: none;
  }
}
