.footer-container {
  width: 100%;
  /*padding: 15px;*/
  padding: 15px 0;
  color: $footer-base-font-color;
  background: $footer-background;
}

.footer-logotype {
  z-index: 10;
  display: inline-block;
  width: $footer-logotype-size;
  height: $footer-logotype-size;
  border-radius: 50%;
  background-color: #ffffff;
  background-image: url(../../../public/images/logotype.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 85%;
}

.footer-time {
  display: inline-block;
  padding-top: 10px;
  color: $footer-time-font-color;
}


.footer-email,
.footer-contacts-item {
  color: $footer-base-font-color;
}

.footer-short-info {
  margin-top: 25px;
  margin-left: 15px;
  @media (max-width: $screen-xs-max) {
    margin-top: 10px;
    margin-left: 0;
  }
}

.footer-contacts-item {
  display: inline-block;
  width: 100%;
  padding-top: 10px;
}

.footer-logotype-container {
  @media (max-width: $screen-xs-max) {
    display: none;
  }
}
