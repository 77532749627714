.rules-container {
  font-weight: normal;
  line-height: $font-size-base * 1.5;
}

.rules-header {
  text-align: center;
}

.rules-well {
  margin-top: 24px;
  padding: 24px;
  border: 10px solid $brand-primary;
}
