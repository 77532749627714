.contacts {
  position: relative;
  height: 100%;
  background-image: url("../../../public/images/way-to-cafe.png");
  background-position-x: center;
  background-size: cover;
}

.contacts-address-container {
  position: absolute;
  z-index: 1;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  transition: top 0s 0.8s, opacity 0.8s;
  opacity: 0;

  &.active {
    top: 0;
    transition: top 0s 0s, opacity 0.8s;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .contacts-ribbon {
    display: block;
    width: $address-block-width + 28 * 2;
  }

  .ribbon-content {
    &:before,
    &:after {
      font-size: 28px !important;
    }
  }
}

.contacts-address-block {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: auto;
  margin-left: auto;
  transform: translate(-50%, -50%);
  text-align: center;
  text-transform: uppercase;
}

.contacts-address-blazon {
  display: inline-block;
  width: $address-block-width;
  margin-right: auto;
  margin-left: auto;
  color: $contacts-blazon-font-color;
  background: $address-block-background;
}

.contacts-house {
  width: 70px;
  margin: 40px auto 10px auto;
  padding: 10px;
  padding-top: 15px;
  border: 1px solid $contacts-blazon-font-color;
  border-radius: 50%;
  font-size: $contacts-house-font-size;
}

.contacts-lane {
  font-size: 12px;
}

.contacts-street {
  width: $contacts-street-sign-size;
  margin: auto;
  padding-bottom: 30px;
  background: url(../../../public/images/street-sign.svg) no-repeat;
  background-position-x: center;
  background-size: $contacts-street-sign-size;
}

.contacts-street-name {
  display: inline-block;
  padding-top: 10px;
}

.contacts-street-front {
  z-index: 10;
}

.contacts-triangle {
  width: $address-block-width; //2
  margin-right: auto;
  margin-left: auto;
  border-width: 40px calc(#{$address-block-width} / 2) 0 calc(#{$address-block-width} / 2);
  border-style: solid;
  border-color: $address-block-background transparent $address-block-background transparent;
}

.contacts-address-anticafe {
  display: inline-block;
  padding: 10px 0;
}

.how-to-go {
  margin-top: 30px;
  color: $contacts-how-to-go-color;
  @media (max-width: $screen-xs-max) {
    margin-top: 5px;
  }
}

.contacts-play {
  border: none;
  background: none;
  font-size: 32px;
}

.contacts-doctor-who {
  height: 400px;
  background: url("../../../public/images/police-box.jpg") no-repeat 7.5rem top;
  background-position-x: center;
}

.contacts-police-box {
  padding-top: 50px;
  background: #ffffff;
}

.contacts-list {
  list-style: none;
  font-size: 18px;
  font-weight: 400;
}

.contacts-item {
  display: block;
  padding-top: 10px;
  color: #808080;
  font-size: 14px;
}

.contacts-link {
  text-decoration: none;
  color: $contacts-link-color;
}

.contacts-social-container {
  padding: 40px 0;
  text-align: center;
  background: $contacts-social-container-background;
}

.contacts-social {
  display: inline-block;
  width: $contacts-social-size;
  height: $contacts-social-size;
  margin: 20px;
  padding: 20px;
  color: $contacts-social-color;
  border-radius: 50%;
  background: $contacts-social-background;
  font-size: $contacts-social-font-size;

  &.facebook:hover {
    background: #3b5998;
  }

  &.vk:hover {
    background: #45668e;
  }

  &.twitter:hover {
    background: #55acee;
  }

  &.instagram:hover {
    background: #3f729b;
  }
}

.contacts-partners-container {
  padding: 40px;
  text-align: center;
  color: $contacts-partners-font-color;
  background: $contacts-partners-container-background;
  font-size: 16px;
}

$heart-color: #a76f67;
.contacts-partners-heart {
  padding: 30px;
  transition: color 1s linear;
  animation: contactsPartnersHeart 3s linear infinite;
}

@keyframes contactsPartnersHeart {
  0% {
    transform: scale(1.1);
    color: saturate($heart-color, 20%);
  }

  50% {
    transform: scale(0.8);
    color: $heart-color;
  }

  100% {
    transform: scale(1);
    color: saturate($heart-color, 10%);
  }
}

.contacts-partner {
  display: inline-block;
  margin: 15px;
  text-decoration: none;
  color: $contacts-partners-font-color;

  &:hover {
    color: lighten($contacts-partners-font-color, 20%);
  }
}

.contacts-about-arrow {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff;
  font-size: 70px;
}

.contacts-navigation {
  position: absolute;
  top: 0;
  width: 100%;
}

.contacts-video-container {
  position: relative;
  overflow-y: hidden;
  width: 100%;
  height: 100%;

  &:hover {
    cursor: url(../../../public/images/pause.svg), auto;
  }
}

.contacts-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contacts-requisite {
  color: #808080;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 25px;
}
