/*
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/tables.scss";
@import "~bootstrap/scss/card.scss";
@import "~bootstrap/scss/forms.scss";
@import "~bootstrap/scss/forms.scss";
*/

/** {
  .box-sizing(border-box);
}
*:before,
*:after {
  .box-sizing(border-box);
}*/
@font-face {
  font-family: 'UnboundedBold';
  src: url('../../public/fonts/Unbounded/static/Unbounded-Bold.ttf') format('truetype');
  //src: url('fontname.eot');
  //src: url('fontname.eot?#iefix') format('embedded-opentype'),
  // url('fontname.woff') format('woff'),
  //url('fontname.svg#fontname') format('svg');
  //font-weight: normal;
  //font-style: normal;
}

@font-face {
  font-family: 'Unbounded';
  src: url('../../public/fonts/Unbounded/static/Unbounded-Regular.ttf') format('truetype');
  //src: url('fontname.eot');
  //src: url('fontname.eot?#iefix') format('embedded-opentype'),
  // url('fontname.woff') format('woff'),
  //url('fontname.svg#fontname') format('svg');
  //font-weight: normal;
  //font-style: normal;
}

html,
body,
#root {
  /* width: 100%;*/
  height: 100%;
  margin: 0 !important;
}

body {
  background-color: $body-bg;
  font-face: "Unbounded";
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: 500;
  line-height: 22px;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

header,
a,
article {
  transition: all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
  text-decoration: none !important;
}

p {
  margin: 0;
  padding-top: 10px;
}

.fa-fixed-width {
  width: 25px;
  text-align: center;
}

.sr-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}


@media (max-width: 768px) {
  .btn-vertical {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
  .btn-vertical > .btn,
  .btn-group > .btn {
    position: relative;
    float: left;
  }
  .btn-vertical > .btn,
  .btn-vertical > .btn-group,
  .btn-vertical > .btn-group > .btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
  }
  .btn-vertical > .btn-group > .btn {
    float: none;
  }
  .btn-vertical > .btn + .btn,
  .btn-vertical > .btn + .btn-group,
  .btn-vertical > .btn-group + .btn,
  .btn-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0;
  }
  .btn-vertical > .btn:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .btn-vertical > .btn:first-child:not(:last-child) {
    border-radius: 0;
  }
  .btn-vertical > .btn:last-child:not(:first-child) {
    border-radius: 0;
  }
  .btn-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
    border-radius: 0;
  }
  .btn-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
  .btn-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .btn-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.btn-group.btn-group-lg .btn {
  border-radius: 0;
  border: none;
}


.ellipse {
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.two-lines {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  min-height: 3.4rem;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: .5;
}

.landingBox {
  font-family: Unbounded, serif;
  color: white;
  //margin: 15px 0;
  padding: 10px;
  background-color: #65C755;
  border: #ffffff73 1px solid;
  h1,h2 {
    font-family: UnboundedBold, serif
  }
}

.landingBoxWhite {
  font-family: Unbounded, serif;
  color: black;
  background-color: white;
  //margin: 15px 0;
  padding: 10px;
  h1,h2 {
    font-family: UnboundedBold, serif
  }
}
.divImg {
  min-height:200px;
  background-size:cover;
  background-position:center;
}

.card-columns {
  @include media-breakpoint-only(xl) {
    column-count: 3;
  }
  @include media-breakpoint-only(lg) {
    column-count: 2;
  }
  @include media-breakpoint-only(md) {
    column-count: 2;
  }
  @include media-breakpoint-only(sm) {
    column-count: 1;
  }
}
.noMobileMargin {
  @media (max-width: 575.98px) {
      margin: 0;
    padding: 0;
  }
}