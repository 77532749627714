.spinner-container {
  width: 100%;
  margin: 16px;
  text-align: center;
}

.spinner-container {
  display: inline-block;
  width: 64px;
  height: 64px;
  animation-name: spinner-loader;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  border: 4px solid $brand-primary;
  border-radius: 50%;
  background-color: #ffffff;
  background-image: url(../../../public/images/logotype.svg);
}

@keyframes spinner-loader {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}
