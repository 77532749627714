$border-size: 25px;

.feed-header {
  padding: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  color: $feed-header-color;
  font-size: 14px;
  font-weight: 400;

  &:after {
    display: block;
    margin-top: -13px;
    margin-bottom: 12px;
    margin-left: -5.5px;
    content: "";
    border-bottom: 1px solid $feed-header-color;
  }
}

.feed-header-time {
  padding: 0 22px;
  background: $body-bg;
}

.event-card-link {
  display: block;
  text-decoration: none;
  color: #000000;
}

.event-card-container {
  position: relative;
  height: 300px;
  margin-bottom: 30px;
  border-radius: 3px;
  background-position: center;
  background-size: cover;
  box-shadow: 0 1px 3px rgba(74, 91, 83, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
}

.event-card-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: rgb(66, 66, 66);
}

.event-card-header {
  margin: 0;
  padding: 11px 22px;
  background: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px * 1.5;
}


.event-card-meta {
  display: inline-block;
  min-width: 200px;
  max-width: 300px;
  height: 1rem;
  margin: auto;
  margin-bottom: 0;
  padding: 3.264px 14.264px;
  vertical-align: bottom;
  text-transform: uppercase;
  border-right: $border-size solid transparent;
  border-bottom: $border-size solid white;
  border-left: $border-size solid transparent;
  border-top-right-radius: 100px;
  background: none;
  font-size: 12px;
  line-height: 2.4rem;


}

.feed-past-events {
  text-align: center;
}

.feed-link {
  color: $link-color;
  text-decoration: none;
}

.feed-hookah-image {
  width: 50%;
  height: auto;
  display: inline-block;

  img {
    width: 100%;
    height: 100%;
  }

  span {
    position: relative;
    bottom: 8%;
    z-index: 20;
    color: white;
    //left: 45%;
    font-size: 30px;
  }
}

.feed-hookah-container {
  display: flex;
  flex-wrap: wrap
}

@media screen and (max-width: 770px) {
  .feed-hookah-image {
    width: 100%
  }
}