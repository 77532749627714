.rent {
  height: 100%;
}

.rent-prices {
  margin-top: 24px;
}

.rent-heading {
  text-align: center;
}

.rent-controls {
  text-align: center;
}

.rent-control-item {
  display: inline-block;
  padding: 5px 15px;

  &:last-child {
    margin-left: 15px;
  }
}

.rent-control-day {
  margin-bottom: 10px;
  border-bottom: 1px solid $rent-control-border-color;

  &.active {
    border-color: $rent-control-active-border-color;
    font-weight: bold;
  }

  &:hover {
    cursor: pointer;
  }
}

.rent-room {
  position: relative;
  margin-top: 15px;
}

.slick-slider {
  margin-bottom: 10px;
}

.slick-prev {
  left: 5px;
}

.slick-next {
  right: 5px;
}

.rent-slide {
  height: auto;
  pointer-events: none;
}

.rent-room-description {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: $rent-description-font-color;
}

.rent-room-capacity {
  width: 75px;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
  background-color: $rent-room-description-background;
}

.rent-room-info {
  margin: 0;
  padding: 10px 0;
  text-align: center;
  background-color: $rent-room-description-background;
  font-size: $rent-room-info-font-size;
}

.rent-feature {
  display: block;
  height: 66px;
  margin: auto;
  opacity: 0.6;
}

.rent-feature-title {
  text-transform: uppercase;
}

.rent-features-list {
  margin-bottom: 40px;
  text-align: center;
}

.rent-clearfix {
  clear: both;
}

.rent-contact {
  max-width: 400px;
  margin: auto;
  margin-bottom: 40px;
  padding: 20px;
  color: $rent-contact-font-color;
  border: 1px solid #e0e0e0;
}

.rent-manager-img {
  max-width: 100%;
  height: 100px;
}

.rent-manager-desc {
  margin: 0;
}

.rent-manager-link {
  color: $rent-contact-font-color;
}

.rent-room-bookmark {
  float: right;
  padding-right: 15px;
  font-size: 24px;
}
