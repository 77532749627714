.about,
.about-slide {
  height: 100%;
  background-color: $about-background-color;
}

.about-slide {
  position: relative;
  z-index: 0;
  background-position: center;
  background-size: cover;
}

.about-slide-nav {
  position: fixed;
  z-index: 1;
  top: 40%;
  right: 10%;
  max-width: 400px;
  padding: 15px;
  color: $about-slide-nav-font-color;
  background: $about-slide-nav-background;
  @media (max-width: $screen-xs-max) {
    left: 10%;
    max-width: none;
  }
}

.about-nav-item {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-top: 10px;
  margin-right: 5px;
  border: 2px solid $about-nav-item-color;

  &.active {
    background: $about-nav-item-color;
  }

  &:hover {
    cursor: pointer;
    border: 3px solid $about-nav-item-color;
  }

  @media (max-width: $screen-xs-max) {
    width: 25px;
    height: 25px;
    margin-right: 20px;
  }
}

.about-price {
  padding-top: 4%;
  font-size: $about-price-font-size;

  //@include container();
}

.about-slide-top {
  background-image: url("../../../public/images/about/top.jpg");
}

.about-slide-yard {
  background-image: url("../../../public/images/about/yard.jpg");
}

.about-slide-location {
  background-image: url("../../../public/images/about/location.jpg");
}

.about-slide-big {
  background-image: url(../../../public/images/about/big.jpg);
}

.about-pricing {
  height: 184px;
  background-image: url(../../../public/images/money.png);
  background-repeat: no-repeat;
  @media (max-width: $screen-xs-max) {
    display: none;
  }
}

.about-slide-nav-content {
  height: 100px; //10
  @media (max-width: $screen-xs-max) {
    height: 150px; //15
  }
}

.about-arrow {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transition: bottom 0.2s ease-out;
  transform: translateX(-50%);
  color: #ffffff;
  font-size: 70px;

  &:hover {
    bottom: 0;
    cursor: pointer;
  }
}

.about-bottom-slide {
  position: relative;
}

.about-footer {
  //position: absolute;
  bottom: 0;
  width: 100%;
}

.about .ribbon {
  padding: 10px 30px;

  .ribbon-content {
    font-size: 28px;
    font-weight: 500;
  }
}

.about-title {
  padding-top: 150px;
}
